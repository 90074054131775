.App {
  text-align: center;
}



#noborder {
  border: none !important;
  box-shadow: none !important;
}

#langButton {
  text-align: left;
  position: fixed;
  bottom: 20px;
  left: 20px;
  box-shadow: none !important;
  z-index: 12000;
}

.jex.text-pink {
  color: #E0C2DA !important;
}
.jex.text-grey {
  color: rgba(255, 255, 255, 0.5) !important;
}

.jex.text-size {
  font-size: 1.2em !important;
  text-align: center;
}
.ui.segment {
  background-color: transparent !important;
}