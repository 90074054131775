@import url('https://fonts.googleapis.com/css?family=Lato');



.countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.countdown-item {
    color: #fff;
    font-size: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1em;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
}

.countdown-item span {
    color: #666;
    font-size: 0.2em;
    font-weight: 600;
    text-transform: uppercase;
}